@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;
		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;
		--primary: 221.2 83.2% 53.3%;
		--primary-foreground: 210 40% 98%;
		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;
		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;
		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;
		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;
		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 221.2 83.2% 53.3%;
		--radius: 0.5rem;
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
		--chart-green: #79be40;
		--chart-yellow: #ffc007;
		--chart-red: #f5a19b;
		--chart-gray: #d3d3d3;
		--chart-blue: #2563eb;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;
		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;
		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;
		--primary: 217.2 91.2% 59.8%;
		--primary-foreground: 222.2 47.4% 11.2%;
		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;
		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;
		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;
		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;
		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 224.3 76.3% 48%;
		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;
		--chart-green: #79be40;
		--chart-yellow: #ffc007;
		--chart-red: #ffc007;
		--chart-gray: #d3d3d3;
		--chart-blue: #2563eb;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}
/*
.hide-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.hide-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 3px;
}

.hide-scrollbar:hover {
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.hide-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
} */

.hide-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
	display: none; /* Chrome, Safari and Opera */
}

/* Hide scrollbar by default but show thumb when scrolling */
.hover\:pr-2:hover {
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

/* For Webkit browsers (Chrome, Safari) */
.hover\:pr-2::-webkit-scrollbar {
	width: 8px;
}

.hover\:pr-2::-webkit-scrollbar-track {
	background: transparent;
}

.hover\:pr-2::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 20px;
	border: transparent;
}

/* Hide scrollbar when not hovering/scrolling */
.hover\:pr-2::-webkit-scrollbar-thumb {
	background-color: transparent;
}

.hover\:pr-2:hover::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
}

/* Add this class for consistent scrollbar behavior */
.custom-scrollbar {
	overflow-y: auto;
	overflow-x: hidden;
	transition: padding 200ms;
	scrollbar-gutter: stable;
}

/* Remove the hover padding change since we're now always reserving space */
.custom-scrollbar:hover {
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

/* Keep your existing scrollbar styling */
.custom-scrollbar:hover {
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 20px;
	border: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: transparent;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
}

/* Always visible scrollbar with same styling */
.always-show-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
	padding-right: 0.5rem;
	overflow-y: scroll !important;
}

.always-show-scrollbar::-webkit-scrollbar {
	width: 8px;
	display: block;
}

.always-show-scrollbar::-webkit-scrollbar-track {
	background: transparent;
}

.always-show-scrollbar::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3) !important;
	border-radius: 20px;
	border: transparent;
	visibility: visible !important;
	opacity: 1 !important;
}

.always-show-scrollbar:hover::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3) !important;
}

/* Parent container that simply fills the available size */
.scrollbar-reserve {
	position: relative;
	width: 100%;
	height: 100%;
}

/* Scrollable content with stable gutter space
   using margin-right:-16px and padding-right:16px */
.scrollbar-reserve-content {
	position: relative;
	margin-right: -16px; /* "pulls" content to the right */
	padding-right: 16px; /* leaves gutter for scrollbar */
	overflow-y: scroll; /* Always show a scrollbar track (even if not needed). */
	overflow-x: hidden;
	height: 100%;
	box-sizing: content-box;

	/* For Firefox, hide the scrollbar by default and show a thin bar on hover */
	scrollbar-width: none;
}

.scrollbar-reserve-content:hover {
	scrollbar-width: thin; /* reveal scrollbar in Firefox upon hover */
}

/* Webkit-based browsers (Chrome, Safari, Edge) */
.scrollbar-reserve-content::-webkit-scrollbar {
	width: 8px; /* the actual scrollbar thickness */
	height: 8px; /* Horizontal scrollbar thickness (if ever used). */
}

/* Track is transparent by default */
.scrollbar-reserve-content::-webkit-scrollbar-track {
	background: transparent;
	transition: background-color 0.2s;
}

/* Thumb is transparent by default */
.scrollbar-reserve-content::-webkit-scrollbar-thumb {
	background-color: transparent;
	transition: background-color 0.2s;
}

/* On hover, reveal the track + thumb */
.scrollbar-reserve-content:hover::-webkit-scrollbar-track {
	background-color: rgba(0, 0, 0, 0.1);
}

.scrollbar-reserve-content:hover::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
}

/* (Optional) For active dragging, darken thumb a bit more */
.scrollbar-reserve-content::-webkit-scrollbar-thumb:active {
	background-color: rgba(0, 0, 0, 0.4);
}
